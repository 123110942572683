import { Box, Container, VStack, Heading, Link, HStack, Text } from "@chakra-ui/react"
import { CustomAccordion } from "@components/Accordion"
import { LOCALE_KEYS, useLocale } from "@app/hooks/useLocale"
import { CustomSelect } from "@components/Select"
import { memo, useState, useMemo, useCallback } from "react"

import type { PageProps } from "@root/types/global"
import type { Props } from "@app/pages/faqs"

const FAQs: React.FC<PageProps<Props>> = ({ page }) => {
  const { accordionLists, title } = page || {}

  const locales = useLocale(LOCALE_KEYS.FAQS)
  const backToTopText = locales?.backToTopText
  const accordionListTitles = useMemo(() => accordionLists?.map(({ title, _key }) => ({ _key, title })), [accordionLists])

  const [activeAccordionList, setActiveAccordionList] = useState(accordionListTitles?.[0]?._key)

  const mappedSelectOptions = accordionListTitles?.map(({ title, _key }: { title: string; _key: string }) => ({
    label: title,
    value: _key,
  }))

  const handleSelectChange = useCallback((newValue: any) => {
    setActiveAccordionList(newValue?.value)
  }, [])

  const activeAccordion = useMemo(
    () => (accordionLists?.length ? accordionLists?.find(({ _key }: { _key: string }) => _key === activeAccordionList) : []),
    [accordionLists, activeAccordionList]
  )

  return (
    <Box as="section" pt={[16, 16, 20]} mb={[10, 24]}>
      <Container>
        <HStack justifyContent="space-around" alignItems="flex-start" wrap="wrap" spacing={0}>
          <Box width={["full", "20%"]}>
            <Heading as="h1" size="h4" mb={4}>
              {title}
            </Heading>
            <Box display={["block", "none"]} mb={6}>
              <CustomSelect
                options={mappedSelectOptions}
                onChange={handleSelectChange}
                isFaqDropdown
                defaultValue={mappedSelectOptions?.[0]}
              />
            </Box>
            <VStack alignItems="flex-start" display={["none", "flex"]} spacing={3}>
              {accordionListTitles?.map(({ title, _key }: { title: string; _key: string }) => {
                const showActiveStyles = _key === activeAccordionList
                return (
                  <Text
                    key={_key}
                    cursor="pointer"
                    fontWeight={showActiveStyles ? 700 : 400}
                    textDecoration={showActiveStyles ? "underline" : "unset"}
                    onClick={() => setActiveAccordionList(_key)}
                  >
                    {title}
                  </Text>
                )
              })}
            </VStack>
          </Box>
          <Box width={["full", "45%"]}>
            <CustomAccordion
              items={activeAccordion?.accordionItems}
              type={activeAccordion?._type}
              buttonColor="brand.lightSand"
              buttonColorSelected="brand.midSand"
              borderDisabled
              paddingEnabled
              panelColor="brand.lightSand"
              key={activeAccordion?._key}
              isFaq
            />
          </Box>
        </HStack>
        {backToTopText && (
          <Link
            href="#"
            display={["block", "none"]}
            fontWeight={700}
            textDecoration="underline"
            mt={7}
            _hover={{
              textDecoration: "unset",
            }}
            fontSize="sm"
          >
            {backToTopText}
          </Link>
        )}
      </Container>
    </Box>
  )
}

export default memo(FAQs)
