import { graphql, PageProps } from "gatsby"
import Page from "@components/FAQs"

export type Props = PageProps<GatsbyTypes.PageFAQsQuery, GatsbyTypes.PageFAQsQueryVariables>

export { Head } from "@components/Head"

export const query = graphql`
  query PageFAQs {
    page: sanityPageFaQs {
      title
      url
      _type
      accordionLists: _rawAccordionLists(resolveReferences: { maxDepth: 10 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Page {...props} {...data} />
export default Component
